<template>
	
	<div class="withdraw_page">
		<div class="p3 bg_white" style="border-bottom: 1px solid #eee;">
			<el-row class="p2 c_black2" type="flex" align="middle">
				<el-col :span="4" class="tl">
					<div class="c_black2" @click="$router.go(-1)">
						<i class="fa fa-angle-left f16"></i>
					</div>
				</el-col>
				<el-col :span=16 class="f16">
					{{$t('提现')}}
				</el-col>
				<el-col :span=4 class="">
					<span></span>
				</el-col>
			</el-row>
		</div>

		<div class="m3 p3 bg_white br6 tl">

			<!-- tab -->
			<div>
				<el-row type="flex" align="middle">
					<el-col :span="2">
						<span></span>
					</el-col>
					<el-col :span="20" style="text-align: center;">
						<el-radio-group v-model="form.tabType" size="small">
							<el-radio-button label="0">{{$t('银行卡')}}</el-radio-button>
							<el-radio-button label="1">{{$t('数字地址')}}</el-radio-button>
						</el-radio-group>
					</el-col>
					<el-col :span="2">
						<span></span>
					</el-col>
				</el-row>
			</div>
			<!-- end tab  -->

			<!-- bank info form  -->
			<div v-if="form.tabType==0">
				<div>
					<div class="pt3">{{$t('银行名称')}}</div>
					<div class="pt2">
						<el-input v-model="form.bank_name" type="text" size="small" :placeholder="$t('银行名称')"></el-input>
					</div>
				</div>

				<div>
					<div class="pt3">{{$t('银行支行')}}</div>
					<div class="pt2">
						<el-input v-model="form.bank_branch" type="text" size="small" :placeholder="$t('银行支行')"></el-input>
					</div>
				</div>

				<div>
					<div class="pt3">{{$t('银行卡号')}}</div>
					<div class="pt2">
						<el-input v-model="form.bank_account" type="text" size="small" :placeholder="$t('银行卡号')"></el-input>
					</div>
				</div>

				<div>
					<div class="pt3">{{$t('姓名')}}</div>
					<div class="pt2">
						<el-input v-model="form.name" type="text" size="small" :placeholder="$t('姓名')"></el-input>
					</div>
				</div>

				<div>
					<div class="pt3">{{$t('身份证ID')}}</div>
					<div class="pt2">
						<el-input v-model="form.idcard" type="text" size="small" :placeholder="$t('身份证ID')"></el-input>
					</div>
				</div>

				<div>
					<div class="pt3">{{$t('联系号码')}}</div>
					<div class="pt2">
						<el-input v-model="form.phone" type="text" size="small" :placeholder="$t('联系号码')"></el-input>
					</div>
				</div>
			</div>

			<!-- address withdraw  -->
			<div v-if="form.tabType==1">
				<div>
					<div class="pt3">{{$t('币种')}}</div>
					<div class="pt2 radio_diy">
						<el-radio-group v-model="form.network" size="small">
							<el-radio label="trc20" border>TRC20</el-radio>
							<el-radio label="erc20" border>ERC20</el-radio>
						</el-radio-group>
					</div>
				</div>

				<div>
					<div class="pt3">{{$t('地址')}}</div>
					<div class="pt2">
						<el-input v-model="form.coin_address" type="text" size="small" :placeholder="$t('地址')"></el-input>
					</div>
				</div>
			</div>
			<!-- end address withdraw  -->

			<div>
				<div class="pt3">{{$t('提现金额')}}</div>
				<div class="pt2">
					<el-input v-model="form.amount" type="text" size="small" :placeholder="$t('提现金额')" @input="form.amount=form.amount.replace(/[^\d\.]/g, '')">
						<template slot="append">USDT</template>
					</el-input>
				</div>
			</div>
			<!-- end bank info form  -->

			<div class="pt3 c_gray f12">
				<el-row>
					<el-col :span="12" class="tl">
						{{$t('账户余额')}} : {{money}}
					</el-col>
					<el-col :span="12" class="tr">
						<router-link to="/withdraw_history">
							{{$t('提现明细')}} <i class="fa fa-angle-right"></i>
						</router-link>
					</el-col>
				</el-row>
			</div>

			<div style="display: none;">
				<div class="pt3">{{$t('选择账户')}}</div>
				<div class="pt2">
					<el-select v-model="account" :placeholder="$t('请选择')" size="small" class="w100">
						<el-option :label="$t('请选择')" value="0"></el-option>
						<el-option v-for="item in accounts" :label="item.title" :value="item.id" v-bind:key="item.id"></el-option>
					</el-select>
				</div>

				<div class="pt3">{{$t('提现金额')}}</div>
				<div class="pt2">
					<el-input v-model="amount" type="text" size="small" :placeholder="$t('请输入提现金额')" @input="amount=amount.replace(/[^\d]/g, '')"></el-input>
				</div>
			</div>

			<div class="pt4 c_gray f12">
				{{$t('最低提现金额为')}}: {{withdraw_min}}USDT, {{$t('低于最低提现金额将不给予信用且无法退回')}}
			</div>

			<div class="pt5">
				<el-button type="primary" class="w100" @click="submit">{{$t('确认提交')}}</el-button>
			</div>
			<div class="pt5 f12 c_gray">
				<i class="fa fa-check-circle-o"></i> {{$t('我已经阅读并同意')}} 《<router-link to="/content_type/7">{{$t('提现服务协议')}}</router-link>》
			</div>
		</div>


		<el-dialog title="" :visible.sync="showBindBank" width="70%" top="37vh" :close-on-click-modal="false" :show-close="false">
			<span>{{$t('请先绑定任意银行卡或数字地址')}}</span>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="$router.push('/bank')">{{$t('马上设置')}}</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<style>
	.radio_diy .el-radio{margin-right:5px;}
</style>

<script>
	export default {
		name: "WithdrawView",
		data() {
			return {
				money: 10000,
				account: "0",
				accounts: [],
				withdraw_min: 100,
				withdraw_fee: 2.5,
				amount: "",
				showBindBank: false,

				
				form: {
					tabType: 0,
					bank_name: '',
					bank_branch: '',
					bank_account: '',
					name: '',
					idcard: '',
					phone: '',
					amount: '',

					network: 'trc20',
					coin_address: '',
				}
			}
		},
		methods: {
			getData() {
				let that = this;
				this.greq('get', '/api/withdraw_info', false, function(rs) {
					if (rs.status) {
						// if (rs.data.accounts.length < 1) {
						// 	that.showBindBank = true;
						// 	return
						// }
						that.money = rs.data.money;
						that.accounts = rs.data.accounts;
						that.withdraw_min = rs.data.withdraw_min;
						that.withdraw_fee = rs.data.withdraw_fee;
					} else {
						that.gerror(rs.msg);
					}
				});
			},
			
			submit(){
				// if( this.account == '0' ){
				// 	this.gerror(this.$t('请选择提现账户'));
				// 	return
				// }
				// if( this.amount < this.withdraw_min ){
				// 	this.gerror(this.$t('请输入正确的提现金额'));
				// 	return
				// }
				// check form
				if( this.form.tabType == 0 ){
					if( !this.form.bank_name ){
						this.gerror(this.$t('请输入银行名称'));
						return
					}
					if( !this.form.bank_branch ){
						this.gerror(this.$t('请输入银行支行'));
						return
					}
					if( !this.form.bank_account ){
						this.gerror(this.$t('请输入银行卡号'));
						return
					}
					if( !this.form.name ){
						this.gerror(this.$t('请输入姓名'));
						return
					}
					if( !this.form.idcard ){
						this.gerror(this.$t('请输入身份证ID'));
						return
					}
					if( !this.form.phone ){
						this.gerror(this.$t('请输入联系号码'));
						return
					}
				} else {
					if( !this.form.coin_address ){
						this.gerror(this.$t('请输入地址'));
						return
					}
				}

				if( !this.form.amount ){
					this.gerror(this.$t('请输入提现金额'));
					return
				}
				
				let that = this;
				this.$parent.loading = true;
				this.greq('post', '/api/withdraw', this.form, function(rs){
					that.$parent.loading = false;
					if( rs.status ){
						that.$router.push('/withdraw_history');
					} else {
						that.gerror(rs.msg)
					}
				}, function(){
					that.$parent.loading = false;
				});
			}
		},
		mounted() {
			this.getData();
		}
	}
</script>

<style>
</style>
